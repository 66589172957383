<template>
  <ModalFormGeneric :id="id" :title="$t('traM.pay.t')" :btnText="$t('btn.sub')" btnIcon="tra" :btnDisabled="isPayment&&valTip()===false || !f.confirm"
      @submit="onSubmit" @reset="onReset">
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12">
        <b-form-group :label="$t('traM.frx')" :label-for="id+'FormRx'" class="text-left">
          <b-form-input :id="id+'FormRx'" type="text" disabled
              :value="getRx" :class="getTextFormat" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('traM.fam')" :label-for="id+'FormAm'" class="text-left">
          <b-form-input :id="id+'FormAm'" type="text" disabled
              :value="getMoney(item.amount, item.currency)" />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('traM.fcr')" :label-for="id+'FormCre'" class="text-left">
          <b-form-input :id="id+'FormCre'" type="text"
              :value="getDate(item.createdAt)" disabled />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row align-items-end" v-if="isPayment">
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('traM.fat')" :label-for="id+'FormTi'" class="text-left">
          <b-form-input :id="id + 'FormTi'" type="text"
              v-model="tip" :min="defMin" :max="defMax" :step="defStep" :state="valTip()" />
          <b-form-invalid-feedback :state="valTip()">
            {{ $t('traM.pay.w1') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-row class="flex-row">
          <b-col cols="6">
            <b-button block variant="outline-primary" class="mb-3" @click="setTip(0.1)">10%</b-button>
          </b-col>
          <b-col cols="6">
            <b-button block variant="outline-primary" class="mb-3" @click="setTip(0.2)">20%</b-button>
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">
        <b-form-group :label="$t('traM.pay.pyh')" :label-for="id + 'FormCn'" class="text-left">
          <b-checkbox :id="id + 'FormCn'" v-model="f.confirm" :state="f.confirm" required>
            <i18n path="traM.pay.pyt" tag="span">
              <template #link>
                <b-link :to="{name: 'AnuTos'}" target="_blank" class="text-primary">{{ $t('traM.pay.ccl') }}</b-link>
              </template>
            </i18n>
          </b-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12">
        <b-form-group :label="$t('traM.pay.sah')" :label-for="id + 'FormSa'" class="text-left">
          <b-checkbox :id="id + 'FormSa'" v-model="f.save" :state="null">
            {{ $t('traM.pay.sad') }}
          </b-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="*">
        <p class="mb-0 pb-0 text-justify">{{ $t('traM.pay.txt') }}</p>
      </b-col>
    </b-form-row>
  </ModalFormGeneric>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import { REST } from '@/components/RestCall';
import { utcToZonedTime } from 'date-fns-tz';

export default {
  data() {
    return {
      f: {
        uuid4: null,
        action: 'SUBMIT',
        save: false,
        tip: null,
        currency: null,
        confirm: false,
      },
      defMin: 0,
      defMax: 250,
      defStep: 0.01,
      tip: null,
    };
  },
  computed: {
    uuid() {
      return this.$store.state.oLogin.uuid;
    },
    getTip() {
      return this.tip === null ? null : parseFloat(this.tip.replace(/,/g, '.'))*100;
    },
    getRx() {
      return this.isPayment ? this.item.receiverName : this.$t('traM.pay.lcha');
    },
    getTextFormat() {
      return this.isPayment ? 'font-weight-normal' : 'font-italic';
    },
    isPayment() {
      return this.item.type === 'PAYMENT';
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
  },
  mounted() {
    this.dataReset();
    this.f.uuid4 = this.item.uuid4;
    this.$bvModal.show(this.id);
  },
  methods: {
    onSubmit() {
      if (this.valTip() !== false && this.f.confirm === true) {
        let self = this;
        REST.patch('/transactions/' + this.item.uuid4, {...this.f, tip: this.getTip, currency: (this.getTip===null) ? null:this.item.currency})
            .then(async function(resp) {
                // show for user
                self.$store.commit('showSuccess',resp.d.message);
                location.href = resp.d.data.data.link;
            }).catch(e => {
                this.$store.commit('showWarn',e.message)
            }).finally(() => {
                this.$bvModal.hide(this.id);
            });
      } else {
        this.$bvModal.hide(this.id);
      }
    },
    onReset(ev) {
      this.dataReset();
      this.$bvModal.hide(this.id);
      this.$emit('reset', ev);
    },
    valTip() {
      if (this.tip === null) return null;
      if (!/^\d+([.,]{1}\d{0,2})?$/.test(this.tip)) {
        return false;
      }
      let val = parseFloat(this.tip.replace(/,/g, '.'));
      return val >= this.defMin && val <= this.defMax;
    },
    getMoney(a, c) {
      if (a == null) return '';
      return this.$n(a/100, { key: 'c', currency: c });
    },
    getDate(v) {
      if (!v) return '';
      return this.$d(utcToZonedTime(v+'Z', this.tz), 'as');
    },
    setTip(v) {
      this.tip = (v * this.item.amount/100).toFixed(2);
    },
    dataReset() {
      this.f.uuid4 = null;
      this.f.save = false;
      this.f.tip = null;
      this.f.currency = null;
      this.f.confirm = false;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
  },
  components: {
    ModalFormGeneric,
  },
}
</script>
